import React from 'react';
import Layout from "../components/layouts";
import Reveal from 'react-reveal';
import ebr1 from "../images/ebr-1.jpg";
import ebr2 from "../images/ebr-2.jpg";
import ebr3 from "../images/ebr-3.jpg";
import ebr4 from "../images/ebr-4.jpg";
import ebr5 from "../images/ebr-5.jpg";
import ebr6 from "../images/ebr-6.jpg";
import ebr7 from "../images/ebr-7.jpg";
import ebr8 from "../images/ebr-8.jpg";
import ebr9 from "../images/ebr-9.jpg";
import ebr10 from "../images/ebr-10.jpg";
import ebr11 from "../images/ebr-11.jpg";
import ebr12 from "../images/ebr-12.jpg";
import ebr13 from "../images/ebr-13.jpg";
import ebr14 from "../images/ebr-14.jpg";
import ebr15 from "../images/ebr-15.jpg";
import ebr16 from "../images/ebr-16.jpg";
import ebrImage from "../images/ebr-invite.jpg";

const BattleRoyal = () => {

  
  return (
    <Layout navBg>
    <div className="ebr-page">
        <div className="ebr-header ebr"></div>
        <div className="ebr-inner">
          <div className="ebr-description">
              <h2>AN UNFORGETTABLE SHOWDOWN OF EARTH'S MIGHTIEST RENEWABLE ENERGY SOURCES IN A BATTLE TO POWER THE PLANET</h2>
              <p><strong>Energy Battle Royale</strong> is an interactive performance and activation featuring various sustainable technologies personified through <strong>energy superheroes</strong> —solar, wind, geothermal, hydrogen, biogas, nuclear, tidal, and ethanol— who perform dynamic <strong>rap battles</strong> and <strong>dance competitions.</strong></p>
              <p>Students are given an <strong>energy power card</strong>, that includes facts & stats, encouraging them to cheer for a power source. Each character showcases the benefits and challenges of their energy source, <strong>promoting environmental awareness.</strong></p>
              <p>This engaging experience fosters <strong>active learning</strong>, sparking <strong>critical thinking</strong> and deepens understanding of how renewable energy contributes to reducing carbon emissions and creating a cleaner, greener planet.</p>
              <p>Energy Battle Royale is customizable to your curriculum, attendees, and event. Beyond the performance, a comic book, video series, live Q&A, and more can be added to expand and reinforce the educational goals.</p>
              <p>Interested in learning more? Please connect with us at <a href="mailto:info@globalinheritance.org">info@globalinheritance.org</a></p>

              <p style={{ marginTop: 40 }}><strong>Recent Events:</strong></p>
              <h2>Energy Battle Royale 2024</h2>
              <div className="embed-container">
                  <iframe src="https://www.youtube.com/embed/VBdqq8BEkic" frameBorder='0' allowFullScreen></iframe>
              </div>

              <h2>Energy Battle Royale 2023</h2>
              <div className="embed-container">
                  <iframe src="https://www.youtube.com/embed/j7ONZzOo-MY" frameBorder='0' allowFullScreen></iframe>
              </div>
              
              <p><a href="https://www.flickr.com/photos/74696088@N00/albums/72177720306610275" target="_blank">Check out the album here!</a></p>
              
              <img src={ebrImage} style={{width: '100%' }}/>

          </div>
      </div>
      <div style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'center'}}>
          <a href="mailto:pb@globalinheritance.org">

          <button type="submit" className="btn-container">
                              <div className="btn large primary">
                                  <span>Contact us for more information</span>
                              </div>
                          </button></a></div>
        
      <div className="ebr-inner" style={{marginTop: 50}}>
          <Reveal effect="animated fadeIn">
            <div className="ebr-gallery">
              <div className="ebr-ex">
                <img src={ebr1} />
              </div>
              <div className="ebr-ex">
                <img src={ebr2} />
              </div>
              <div className="ebr-ex">
                <img src={ebr3} />
              </div>
              <div className="ebr-ex">
                <img src={ebr4} />
              </div>
              <div className="ebr-ex">
                <img src={ebr5} />
              </div>
              <div className="ebr-ex">
                <img src={ebr6} />
              </div>
              <div className="ebr-ex">
                <img src={ebr7} />
              </div>
              <div className="ebr-ex">
                <img src={ebr8} />
              </div>
              <div className="ebr-ex">
                <img src={ebr9} />
              </div>
              <div className="ebr-ex">
                <img src={ebr10} />
              </div>
              <div className="ebr-ex">
                <img src={ebr11} />
              </div>
              <div className="ebr-ex">
                <img src={ebr12} />
              </div>
              <div className="ebr-ex">
                <img src={ebr13} />
              </div>
              <div className="ebr-ex">
                <img src={ebr14} />
              </div>
              <div className="ebr-ex">
                <img src={ebr15} />
              </div>
              <div className="ebr-ex">
                <img src={ebr16} />
              </div>
            </div>
          </Reveal>
        </div>
    </div>
    </Layout>
);
}

export default BattleRoyal;


